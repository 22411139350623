import type { TenantKey } from 'data/tenant-ids'
import type { CountryCode } from 'types/generic/geographic'
import type { WarehouseRegion } from 'types/models/storefront'

type StorefrontCode = Lowercase<string>
type ContentKey = Lowercase<string>
type RegionCode = string

// @link https://www.notion.so/Default-vs-Localised-content-3d93b9bbbad04b7d9b4d61d2ab522393
export interface StorefrontConfiguration {
  storefrontCode: StorefrontCode
  contentLocale: string // Used for storyblok translations, consider renaming key to translationKey.
  contentKey: ContentKey // Used for storyblok content. Enables folder-based overrides. If the folder does not exist, it takes the content inside /_default
  tenantKey?: TenantKey // Used for XO Tenant. Fallback to storefront.
  warehouseRegion: WarehouseRegion
  markets: Uppercase<CountryCode>[]
  blockedRegions?: {
    [key in Uppercase<CountryCode>]?: RegionCode[]
  }
}

export const storefrontsConfiguration: StorefrontConfiguration[] = [
  {
    storefrontCode: 'uk',
    tenantKey: 'UK',
    contentKey: 'uk',
    contentLocale: 'en-GB',
    warehouseRegion: 'NL',
    markets: ['GB'],
  },
  {
    storefrontCode: 'de',
    tenantKey: 'DE',
    contentKey: 'de',
    contentLocale: 'de-DE',
    warehouseRegion: 'NL',
    markets: ['DE'],
  },
  {
    storefrontCode: 'at',
    contentLocale: 'de-DE',
    warehouseRegion: 'NL',
    tenantKey: 'DE',
    contentKey: 'de',
    markets: ['AT'],
  },
  {
    storefrontCode: 'be',
    contentLocale: 'default',
    warehouseRegion: 'NL',
    tenantKey: 'NL',
    contentKey: 'eu',
    markets: ['BE'],
  },
  {
    storefrontCode: 'ch',
    contentLocale: 'de-DE',
    warehouseRegion: 'NL',
    tenantKey: 'DE',
    contentKey: 'de',
    markets: ['CH'],
  },
  {
    storefrontCode: 'nl',
    tenantKey: 'NL',
    contentLocale: 'default',
    warehouseRegion: 'NL',
    contentKey: 'eu',
    markets: ['NL'],
  },
  {
    storefrontCode: 'us',
    tenantKey: 'US',
    contentKey: 'us',
    contentLocale: 'default',
    warehouseRegion: 'US',
    markets: ['US'],
    blockedRegions: {
      US: [
        'AS', // American Samoa
        'GU', // Guam
        'FM', // Micronesia
        'MH', // Marshall Islands
        'MP', // Northern Mariana Islands
        'PW', // Palau
        'AA', // Armed Forces Americas
        'AE', // Armed Forces Europe, Canada, Africa and Middle East
        'AP', // Armed Forces Pacific
        'VI', // Virgin Islands
      ],
    },
  },
  {
    storefrontCode: 'eu',
    tenantKey: 'EU',
    contentKey: 'eu',
    contentLocale: 'default',
    warehouseRegion: 'NL',
    markets: [
      'SE',
      'CZ',
      'DK',
      'EE',
      'ES',
      'FI',
      'HR',
      'HU',
      'IE',
      'IT',
      'LT',
      'LU',
      'LV',
      'NO',
      'PL',
      'PT',
      'SI',
      'SK',
      'FR',
    ],
  },
  {
    storefrontCode: 'gl',
    tenantKey: 'GL',
    contentKey: 'gl',
    contentLocale: 'default',
    warehouseRegion: 'NL',
    markets: ['AU', 'AE', 'IL', 'NZ', 'SG', 'JP', 'KR'],
  },
]
