<script setup lang="ts">
import { getContentByCountryCode } from 'lib/content'

const headerStore = useHeaderStore()
const { isTopBannerVisible, isTopBannerExcludedRoute, headerHidden, desktopSubmenuOpen } = storeToRefs(headerStore)
const banner = ref<HTMLElement>()
const header = ref<HTMLElement>()

/* Top bar content */
const { state: storyblok } = storeToRefs(useStoryblokStore())
const { currentMarketCountryCode, currentStorefrontCode } = storeToRefs(useStorefrontStore())
const topBannerContent = computed(() =>
  getContentByCountryCode(
    storyblok.value.topBanner,
    currentMarketCountryCode.value,
    currentStorefrontCode.value,
  ),
)
const isHeaderPushed = computed(() => !isTopBannerExcludedRoute.value && isTopBannerVisible.value)
useUpdateCSSHeaderHeight()
const topBannerLink = computed(() => currentStorefrontCode.value === 'us' ? storyblok.value.topBanner?.linkUs?.cached_url : storyblok.value.topBanner?.link?.cached_url)
/* Inline composable for keeping track of header height in CSS */
function useUpdateCSSHeaderHeight() {
  const isUnmounted = ref(false)

  function updateCSSVar() {
    nextTick(() => {
      if (isUnmounted.value)
        return

      const headerHeight = header.value?.clientHeight ?? 0
      document.body.style.setProperty('--header-main-height', `${headerHeight}px`)

      const bannerHeight = isHeaderPushed.value ? banner.value?.clientHeight ?? 0 : 0
      document.body.style.setProperty('--top-banner-height', `${bannerHeight}px`)

      document.body.style.setProperty('--header-and-top-banner-height', `${headerHeight + bannerHeight}px`)
    })
  }
  watch([headerHidden, isHeaderPushed], () => updateCSSVar())
  useEventListener('resize', updateCSSVar)
  onMounted(() => (updateCSSVar()))
  onBeforeUnmount(() => (isUnmounted.value = true))
}
</script>

<template>
  <div v-if="!isTopBannerExcludedRoute" ref="banner" class="header-banner">
    <Banner :message="topBannerContent" :link="topBannerLink" />
  </div>

  <header
    ref="header"
    class="header-main"
    :class="[{
      'header-main--pushed': isHeaderPushed,
      'header-main--hidden': headerHidden,
    }]"
    :aria-label="$t('mainHeader')"
  >
    <HeaderMobile />
    <HeaderDesktop @submenu-open="(open) => desktopSubmenuOpen = open" />
  </header>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

.header-banner {
  height: fit-content;
  overflow: hidden;
}

.header-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // Must be 17 to override the external styles of the chatbox
  z-index: 17;

  transition: transform 0.3s;

  &--pushed {
    transform: translateY(var(--top-banner-height));
  }

  &--hidden {
    transform: translateY(-100%);
  }
}
</style>
