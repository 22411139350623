<script setup lang="ts">
import type { UILink } from '~/types/storyblok'
import { useSpeechBubbleStore } from '~/stores/speechBubble'
import { focusNextDOMElement } from '~/lib/helpers/focusNextDOMElement.js'

const storyblokStore = useStoryblokStore()
const { state: storyblok } = storeToRefs(storyblokStore)
const speechBubbleStore = useSpeechBubbleStore()
const { disableRegularNewsletter } = useSegment()

const { links: headerNavbarLinks } = useHeaderNavbarItems()

const isMenuOpen = ref(false)
const isSubMenuOpen = ref(false)
const categorySelected = ref<UILink | null>(null)

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value
  blockScroll()
  isSubMenuOpen.value = false
  toggleSpeechBubble()
}
function toggleSpeechBubble() {
  if (isMenuOpen.value)
    speechBubbleStore.setSpeechBubble('menu-expanded', speechBubbleStore.getStates()?.menuExpanded)
  else
    speechBubbleStore.hideSpeechBubble()
}
function closeMenu() {
  isMenuOpen.value = false
  toggleSpeechBubble()
  document.body?.classList.remove('lock-scroll')
}
function blockScroll() {
  return isMenuOpen.value
    ? document.body?.classList.add('lock-scroll')
    : document.body?.classList.remove('lock-scroll')
}

function openSubmenu(link: UILink) {
  categorySelected.value = link
  isSubMenuOpen.value = true
}

function closeSubmenu() {
  isSubMenuOpen.value = false
}

const { links: submenuLinks } = useSubmenuLinks(categorySelected)

const isLogoCollapsed = ref(false)

function setLogoCollapsed() {
  isLogoCollapsed.value = true
}
const menuPadding = computed(() => {
  if (isMenuOpen.value)
    return speechBubbleStore.height + 36
  else
    return 0
})
</script>

<template>
  <div class="navbar">
    <div
      class="navbar__top" :class="[{ ' navbar__top--without-radius': isMenuOpen }]"
      :style="{ paddingBottom: `${menuPadding}px` }"
      :aria-label="$t('topNavigationBar')"
    >
      <button
        :class="[{ open: !isMenuOpen, close: isMenuOpen }]"
        data-test="menu-icon"
        :aria-label="$t('openMenu')"
        @click.prevent="toggleMenu"
      />
      <HeaderLogo
        :is-logo-collapsed="isLogoCollapsed"
        device="mobile"
        @logo-collapse="setLogoCollapsed()"
      />
      <HeaderCartLink />
    </div>

    <div
      v-show="isMenuOpen"
      class="navbar__main" :class="[{ 'navbar__main--overflow-hidden': isSubMenuOpen }]"
      :aria-label="$t('mainMenu')"
    >
      <div class="menu" :class="[{ 'menu--slide': isSubMenuOpen }]">
        <HeaderSearch
          :expanded="true"
          :expand-on-focus="false"
          :has-standard-width="true"
          device="mobile"
          :is-menu-open="isMenuOpen"
          @search-query="closeMenu()"
          @focus-next-element="focusNextDOMElement($event, '[data-accessibility=nav-link]')"
        />
        <HeaderNavLinksList
          class="menu__items"
          :items="headerNavbarLinks ?? []"
          @click="openSubmenu"
        />
        <HeaderCountryAndSocial />
        <Newsletter
          v-if="storyblok.newsletter && !disableRegularNewsletter"
          :newsletter="storyblok.newsletter"
          background-color="var(--pink)"
          context="menu"
        />
      </div>
      <HeaderSubmenuLinks
        class="submenu" :class="[{ 'submenu--slide': isSubMenuOpen }]"
        :category-selected="categorySelected?.text ?? ''"
        :navigation-items="submenuLinks ?? []"
        direction="column"
        device="mobile"
        @close="closeSubmenu"
        @redirect="closeMenu"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

.navbar {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 0.2rem;
  &__top {
    min-height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--gray);
    border-radius: 2px;
    &--without-radius {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &__main {
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    min-height: calc(100vh - 28rem);
    &--overflow-hidden {
      overflow-y: hidden;
    }
  }
  @media (min-width: $tablet) {
    display: none;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  transform: translateX(0);
  transition: transform 0.5s ease-out;
  &__items {
    width: 100%;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
  &--slide {
    transform: translateX(-100%);
  }
}

.submenu {
  position: absolute;
  top: -6rem;
  width: 100%;
  background-color: var(--gray);
  transform: translateX(100%);
  transition: transform 0.5s ease-out;
  &--slide {
    transform: translateX(0);
    height: calc(100% + 6rem);
  }
}
.open {
  width: 5rem;
  height: 2.5rem;
  background-color: var(--black);
  mask: url('/icons/menu.svg') no-repeat center / contain;
}
.close {
  width: 5rem;
  height: 1.2rem;
  background-color: var(--black);
  mask: url('/icons/close.svg') no-repeat center / contain;
}
</style>
