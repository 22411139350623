import type { Environment } from 'types/environment'

export const addressTypes = ['shipping', 'billing'] as const

export type AddressType = typeof addressTypes[number]

export interface Address {
  city: string
  street0: string
  street1?: string
  region: string
  country?: Environment.CountryCode
  postCode: string
  lastName: string
  firstName: string
}

export interface Information {
  email: string
  telephone: string
  agreements: boolean // legal, gdpr, etc.
  newsletterSubscription: boolean
}
