import { getDisplayVariant, getProductPrice } from 'lib/product'
import { normalizePrice } from 'lib/price'

import type { Product, ProductVariant } from 'types/models/product'
import type { Cart, CartItem } from 'types/models/cart.model'
import type { Environment } from 'types/environment'

import type {
  Product as GtmProduct,
  ProductVariant as GtmProductVariant,
  ProcessedProduct,
} from 'types/gtm'
import { getTenant } from '~~/helpers/tenant'
import type { RouteLocationNormalizedLoaded } from '#vue-router'
import { getActiveSplitTests, getSplitTestCookieString } from '~/configuration/split-testing.configuration'

export function getListFromRoute(route?: RouteLocationNormalizedLoaded): string {
  // Initial page load
  if (!route)
    return ''

  const urlParts = route.path.split('/').filter(Boolean)

  // homepage (I.E. /[storefront])
  if (urlParts.length === 1)
    return 'homepage'

  // search
  if (route.name === 'storefront-search')
    return `search:${route.query.term}`.toLowerCase()

  // collection:wwf (I.E /eu/collection/wwf)
  return urlParts.slice(1, urlParts.length).join(':').toLowerCase()
}

export function processCartData(cartItems: Cart['items'], storeCurrency: Environment.Currency['code']): ProcessedProduct[] {
  const ratesStore = useRatesStore()

  return cartItems.map(item => ({
    id: item.sku, // variantSku
    barcode: item.id,
    name: item.name,
    price: item.price.final,
    priceEur: ratesStore.convert(item.price.final, storeCurrency),
    original_price: item.price.original,
    currency: storeCurrency,
    variant: `Size: ${item.size.value}`,
    quantity: item.quantity,
    parentSku: item.parentSku,
    sku: item.sku,
  }))
}

export function processImpressionData(products: Product[], list: string, collectionContent: boolean, collectionIndex: number) {
  const processedData: ProcessedProduct[] = []
  products.forEach((product, i) => {
    const variant = getDisplayVariant(product)
    processedData[i] = {
      id: product.sku, // parentSku
      variantId: variant.sku, // sku
      barcode: variant.id,
      list,
      name: product.name,
      price: variant.price.final,
      original_price: variant.price.original,
      dimension12: product.stockStatus,
      metric1: variant.discount.percentage,
      collectionContent,
      collectionIndex,
      sku: variant.sku,
      parentSku: product.sku,
      badges: product.badges,
    }
    i++
  })

  return processedData
}

export function normalizeProductItem(item: Product, variant: ProductVariant, qty: number, list: string, currency: Environment.Currency): GtmProduct {
  const ratesStore = useRatesStore()

  const { originalPrice, finalPrice } = getProductPrice(currency, variant)
  const normalizedFinalPrice = normalizePrice(finalPrice)

  const priceEur = ratesStore.convert(normalizedFinalPrice, currency.code)

  return {
    id: item.sku,
    variantId: variant.sku,
    barcode: variant.id,
    name: item.name,
    price: normalizedFinalPrice,
    original_price: normalizePrice(originalPrice),
    priceEur,
    list,
    quantity: qty,
    variant: `Size: ${variant.size}`,
    dimension12: variant.stockStatus,
    sku: variant.sku,
    parentSku: item.sku,
    badges: item.badges,
  }
}

export function normalizeCartItem(item: CartItem, qty: number, currencyCode: Environment.Currency['code']): GtmProductVariant {
  const ratesStore = useRatesStore()
  const priceEur = ratesStore.convert(item.price.final, currencyCode)

  return {
    id: item.parentSku,
    variantId: item.sku,
    barcode: item.itemId,
    name: item.name,
    price: item.price.final,
    original_price: item.price.original,
    priceEur,
    quantity: qty,
    variant: `Size: ${item.size.value}`,
    dimension12: item.stockStatus,
    sku: item.sku,
    parentSku: item.parentSku,
  }
}

// Get the widget ID based on the route and widgetId from Storyblok
export function getXoWidgetId(storefront: Environment.StorefrontCode): string | null {
  const route = useRoute()
  const config = useRuntimeConfig()
  const name = route.name as string

  const currentWidgetId = useProductStore().currentWidgetId || null

  const {
    searchConfigId,
    widgets: { plp, pdp },
  } = getTenant(storefront, config.public.XOEnvironment)

  if (!name)
    return null

  if (name.includes('product'))
    return pdp
  if (name.includes('search'))
    return searchConfigId

  return currentWidgetId || plp
}

export function getSplitTestVariants() {
  const storefrontStore = useStorefrontStore()
  const storefront = storefrontStore.currentStorefrontCode
  const activeSplitTests = getActiveSplitTests(storefront)

  return activeSplitTests.reduce((acc, test) => {
    const cookieName = getSplitTestCookieString(test.id)
    acc[test.id] = useCookie(cookieName).value || 'none'
    return acc
  }, {} as Record<string, string>)
}
