import type * as Brink from 'types/vendors/brink'
import { convertToAddress, getBrinkOrderItemsPrice, getShipmentOrderLine, normalizeBrinkPrice, normalizeOrderItems } from '~/lib/brink'
import { getItemsCountWithQuantity } from '~/lib/cart'
import type { ShippingMethod } from '~/types/models/cart.model'
import type { Order } from '~/types/models/order'

export function normalizePostCartOrderToOrder(_order: Brink.PostAdyenOrderResponse, cart: CartState, shippingMethod: ShippingMethod | undefined): Order {
  const storefrontStore = useStorefrontStore()
  const currency = _order.currencyUnit
  const { address, information } = convertToAddress(_order.billingAddress, _order.shippingAddress.email)
  const shipmentLine = getShipmentOrderLine(_order.orderLines)
  const orderStatus = _order.status ?? _order.statusLog[_order.statusLog.length - 1]?.status

  if (!shipmentLine)
    throw new Error('No shipment line found in order')

  const orderItems = _order.orderLines.filter(item => item.type === 'physical')
  const items = normalizeOrderItems(orderItems, cart.items, currency)

  const order: Order = {
    id: _order.id,
    status: orderStatus ?? 'unknown',
    currency,
    items,
    itemsCount: getItemsCountWithQuantity(orderItems),
    orderNumber: String(_order.reference),
    priceTax: normalizeBrinkPrice(_order.orderTaxAmount, currency),
    priceProducts: getBrinkOrderItemsPrice(orderItems, currency),
    priceShipping: normalizeBrinkPrice(shipmentLine.totalAmountWithDiscount, currency),
    priceDiscount: cart.priceDiscount,
    priceTotal: normalizeBrinkPrice(_order.orderAmountWithDiscount, currency),
    storefront: storefrontStore.current,
    market: storefrontStore.currentMarket,
    payment: {
      status: orderStatus,
      method: _order.paymentMethod ?? '',
    },
    address: {
      billing: address,
      shipping: convertToAddress(_order.shippingAddress).address,
    },
    shippingMethod: {
      name: shipmentLine.name,
      estimatedDeliveryTime: shippingMethod?.deliveryDetails ?? '0', // Get from cart data
    },
    contact: {
      firstName: address.firstName,
      lastName: address.lastName,
      email: information.email,
      telephone: information.telephone,
    },
    externalTaxAmount:
      _order.externalTaxRate && normalizeBrinkPrice(_order.orderTaxAmount, currency),
    createdAt: _order.created ?? new Date().toISOString(),
    updatedAt: _order.lastUpdated ?? new Date().toISOString(),
  }

  return order
}
