<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { HIDDEN_FLAG_COUNTRIES } from '~/configuration/global.configuration'

interface Props {
  showText?: boolean
  size?: number
}

withDefaults(defineProps<Props>(), {
  showText: true,
  size: 2.75,
})

const emit = defineEmits(['click'])
const storefrontStore = useStorefrontStore()
const { currentMarket } = storeToRefs(storefrontStore)
const onClick = () => emit('click')

const hideFlag = computed(() => {
  return HIDDEN_FLAG_COUNTRIES.includes(currentMarket.value.countryCode)
})
</script>

<template>
  <div class="country-switcher">
    <p class="title">
      {{ $t('shippingDestination') }}
    </p>
    <div class="lang-switcher">
      <button :aria-label="$t('changeLocale')" @click="onClick">
        <span
          v-if="hideFlag"
          class="flag flag-hide"
        />
        <span
          v-else
          class="flag"
          :style="{
            backgroundImage: `url(/flags/${currentMarket.countryCode.toLowerCase()}.png)`,
          }"
        />
        <span v-if="showText" class="lang-name">
          <span class="country-name">{{ currentMarket.name.english }}</span>
          <span class="btn-label">{{ $t('change').toUpperCase() }}</span>
        </span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/breakpoints';

$phone-large: 350px;

.country-switcher {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
  color: var(--text-secondary);
  font-weight: lighter;
}

.lang-switcher {
  font-size: 1.2rem;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag {
  width: 1.8rem;
  height: 1.2rem;
  background-size: cover;
  background-position: 50% 50%;
}

.flag-hide {
  background-color: white;
}
.lang-name {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  gap: 2.4rem;
  span {
    @include body2;
  }
  .btn-label {
    @include caption1;
    margin-left: 0.5rem;
    background-color: var(--gray);
    padding: 0.5rem 0.8rem;
    border-radius: 0.2rem;
  }
}

.country-name {
  white-space: normal;
  text-align: start;
}

@media (min-width: $phone-large) {
  .country-name {
    white-space: nowrap;
  }
}
</style>
