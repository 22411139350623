<script lang="ts">
// use normal <script> to declare options
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
const cartStore = useCartStore()

const count = computed(() => cartStore.state.itemsCount)
const animate = ref(false)
onMounted(() => {
  setTimeout(() => {
    animate.value = true
  }, 500)
})
</script>

<template>
  <span class="container" v-bind="$attrs" role="status">
    <span class="cart">
      <span class="cart__icon" />
    </span>
    <span class="count" :class="[{ 'slide-in': count > 0 && animate }]">
      {{ count }}
    </span>
    <span class="visually-hidden"> {{ $t('productsInCart') }}</span>
  </span>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
.container {
  display: flex;
  overflow: hidden;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.cart {
  background-color: var(--gray);
  width: 4.8rem;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &__icon {
    mask: url('/icons/cart.svg') no-repeat center / contain;
    width: 1.8rem;
    height: 1.8rem;
    background-color: var(--black);
  }
}
.count {
  background-color: var(--green);
  width: 4.8rem;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -5rem;
  transition: margin-right 0.3s ease;
}
.slide-in {
  margin-right: 0;
}
</style>
