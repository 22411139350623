import { price } from 'lib/price'

export default defineNuxtPlugin({
  name: 'price',
  parallel: true,
  dependsOn: ['storefront'],
  async setup(nuxtApp) {
    nuxtApp.provide('price', price)
  },
})
