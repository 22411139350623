<script setup lang="ts">
import { capitalize } from 'lib/strings'
import type { Submenu } from '~/types/storyblok/shared-content/navbar'

type Direction = 'row' | 'column'
type Device = 'desktop' | 'mobile'

interface Props {
  categorySelected: string
  navigationItems: Submenu[]
  direction: Direction
  device: Device
}

defineProps<Props>()

const emit = defineEmits(['close', 'redirect'])
</script>

<template>
  <div class="nav">
    <button v-show="device === 'mobile'" class="chevron-left" @click="emit('close')" />
    <nav>
      <ul :class="[direction]">
        <li v-for="(column, columnIndex) in navigationItems" :key="columnIndex" class="container">
          <span v-show="device === 'mobile' && columnIndex === 0" class="main-section-title">
            {{ categorySelected }}
          </span>
          <span v-show="column.name" class="section-title">
            {{ capitalize(column.name) }}
          </span>
          <ul>
            <template v-for="(item, itemIndex) in column.links">
              <li v-if="!item.disabled" :key="itemIndex">
                <NuxtLink
                  :to="$url(item.url)"
                  :target="item.url.startsWith('http') ? '_blank' : '_self'"
                  class="item" :class="[{ highlighted: item.isHighlighted }]"
                  @click="emit('redirect')"
                >
                  <div v-if="item.isHighlighted" class="blinking">
                    <svg
                      width="10"
                      height="11"
                      viewBox="0 0 10 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="5" cy="5.75" r="5" fill="white" />
                    </svg>
                  </div>
                  {{ item.text }}
                </NuxtLink>
              </li>
            </template>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/breakpoints';

.nav {
  max-height: 90vh;
  display: block;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  margin-top: -0.1rem;
  padding-top: 6rem;
}
ul {
  margin-top: 0.8rem;
}

.item {
  @include body2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1.4rem 1.6rem;
  gap: 1rem;
  align-items: baseline;
  cursor: pointer;
  font-size: 1.6rem;
  @media (min-width: $large-breakpoint) {
    font-size: 1.4rem;
    padding: 0.4rem 1.6rem;
  }
  &:not(.highlighted) {
    outline-offset: -2px;
  }
}

.item:hover {
  color: var(--text-secondary);
}

.column {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  .item {
    border-bottom: 1px solid var(--divider);
  }
  .container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
  .container:nth-child(n + 2) {
    margin-top: 3.2rem;
    @media (min-width: $large-breakpoint) {
      margin-top: 2.5rem;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 0 1rem;

  .item {
    border-bottom: unset;
    cursor: pointer;
  }

  .container {
    width: 30%;
  }
}
.highlighted {
  background-color: var(--blue);
  color: var(--white);
  border-radius: 0.2rem;
  // &:hover {
  //   background-color: var(--pink);
  // }
}

.blinking {
  animation: 2s blink linear infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

.chevron-left {
  width: 1.5rem;
  height: 1.5rem;
  margin: 1rem 1.5rem;
  background-color: var(--black);
  mask: url('/icons/chevron-left.svg') no-repeat center / contain;
}

.main-section-title {
  margin: 1rem 1.6rem;
  font-weight: bold;
}
.section-title {
  font-size: 1.2rem;
  margin: 1rem 1.6rem;
  color: var(--text-secondary);
  font-weight: lighter;
}
</style>
