<script lang="ts" setup>
import type { ImageObject } from '~/types/models/product'

interface Props {
  image: {
    url: string
    label: string
  }
  imageAlternative?: ImageObject
  lazy?: boolean
}

const props = defineProps<Props>()
const myHoverableElement = ref()
const isHovered = useElementHover(myHoverableElement)
const isAlternateImageLoaded = ref(false)

// Preload alternative image if it exists
onMounted(() => {
  if (props.imageAlternative) {
    const img = new Image()
    img.src = props.imageAlternative.url
    img.onload = () => {
      isAlternateImageLoaded.value = true
    }
  }
})

const showAlternateImage = computed(() => props.imageAlternative && isHovered.value && isAlternateImageLoaded.value)
</script>

<template>
  <div ref="myHoverableElement">
    <ImageResponsive
      v-show="!showAlternateImage"
      provider="fastly"
      :src="image.url"
      :alt="image.label"
      :max-width="720"
      :columns="[2, 4]"
      ratio="4:5"
      object-fit="contain"
      data-test="collection-product-image"
      :lazy="lazy"
      background-color="#FFFFFF"
    />
    <ImageResponsive
      v-if="imageAlternative"
      v-show="showAlternateImage"
      provider="fastly"
      :src="imageAlternative.url"
      :alt="imageAlternative.label || image.label"
      :max-width="720"
      :columns="[2, 4]"
      ratio="4:5"
      object-fit="contain"
      data-test="collection-product-image"
      :lazy="true"
      background-color="#FFFFFF"
    />
  </div>
</template>

<style scoped>

</style>
