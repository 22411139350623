import type { StoryblokContent } from '../storyblok'
import type { Asset, EdgeStyles, Link } from './shared'

export interface HeroBlockV2 extends StoryblokContent {
  component: 'hero-block-v2'

  // Settings
  heightLimit: 'none' | 'full' | 'original'
  edgeStyle: EdgeStyles

  //Video Controls
  enable_video_controls: boolean

  // Media - Default & Mobile
  smallAsset: Asset
  smallAssetVideoVimeoId: string
  smallAssetVideoWidth: string
  smallAssetVideoHeight: string
  altTextSmall: string

  // Media - Desktop override
  largeAsset: Asset
  largeAssetVideoVimeoId: string
  largeAssetVideoWidth: string
  largeAssetVideoHeight: string
  altTextLarge: string

  // Heading
  headingType: (HeroStandardHeading | HeroOverlayHeading)[]
  is_main_page_heading: boolean
  is_below_image: boolean
  screen_reader_heading: string

  // CTA
  label: string
  link: Link
  ctaBackgroundColor: string
  ctaTextColor: string
  screen_reader_link_label: string

  // Description
  descriptionText: string
  descriptionTextColor: string
}

export interface HeroStandardHeading {
  component: 'heroStandardHeading'
  headingText: string
  textColor: string
}

export interface HeroOverlayHeading {
  component: 'heroOverlayHeading'
  overlay: Asset
  altTextOverlay: string
}

export interface HeroBlockAsset {
  url?: string
  vimeoVideoId?: string
  alt: string
  videoAspectRatio?: [number, number]
  enableVideoControls?: boolean
}

export type HeroBlockV2Normalized = ReturnType<typeof normalizeHeroBlockV2>
export function normalizeHeroBlockV2(blok?: HeroBlockV2) {
  const defaultAsset: HeroBlockAsset | undefined = blok?.smallAsset.filename || blok?.smallAssetVideoVimeoId
    ? {
        url: blok?.smallAsset.filename || undefined,
        vimeoVideoId: blok?.smallAssetVideoVimeoId || undefined,
        alt: blok?.altTextSmall || '',
        videoAspectRatio: blok.smallAssetVideoWidth && blok.smallAssetVideoHeight
          ? [Number.parseInt(blok.smallAssetVideoWidth), Number.parseInt(blok.smallAssetVideoHeight)]
          : undefined,
        enableVideoControls: blok.enable_video_controls,
      }
    : undefined
  const desktopOverrideAsset: HeroBlockAsset | undefined = blok?.largeAsset.filename || blok?.largeAssetVideoVimeoId
    ? {
        url: blok?.largeAsset.filename || undefined,
        vimeoVideoId: blok?.largeAssetVideoVimeoId || undefined,
        alt: blok?.altTextLarge || '',
        videoAspectRatio: blok.largeAssetVideoWidth && blok.largeAssetVideoHeight
          ? [Number.parseInt(blok.largeAssetVideoWidth), Number.parseInt(blok.largeAssetVideoHeight)]
          : undefined,
        enableVideoControls: blok.enable_video_controls,
      }
    : undefined

  return {
    heightLimit: blok?.heightLimit || 'original',
    edgeStyle: blok?.edgeStyle || 'none',
    description: {
      text: blok?.descriptionText || '',
      color: blok?.descriptionTextColor || 'black',
    },
    asset: {
      default: defaultAsset,
      desktopOverride: desktopOverrideAsset,
    },
    heading: {
      overlay: blok?.headingType?.[0]?.component === 'heroOverlayHeading'
        ? {
            url: blok.headingType[0].overlay.filename,
            alt: blok.headingType[0].altTextOverlay,
          }
        : undefined,
      standard: blok?.headingType?.[0]?.component === 'heroStandardHeading'
        ? {
            heading: blok.headingType[0].headingText,
            headingColor: blok.headingType[0].textColor,
          }
        : undefined,
      isMainPageHeading: blok?.is_main_page_heading || false,
      isBelowImage: blok?.is_below_image || false,
    },
    screenReaderHeading: blok?.screen_reader_heading || '',
    cta: {
      label: blok?.label,
      url: blok?.link?.cached_url,
      backgroundColor: blok?.ctaBackgroundColor,
      textColor: blok?.ctaTextColor,
      screenReaderLinkLabel: blok?.screen_reader_link_label,
    },
  }
}
