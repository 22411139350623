import type * as Brink from 'types/vendors/brink'
import { BrinkCartErrorCodes, BrinkPaymentErrorCodes } from 'types/vendors/brink'
import { isValidEnvironmentCountryCode } from 'types/guards/storefront'
import type { CartItem, ShippingMethod } from 'types/models/cart.model'
import type * as Contact from 'types/models/contact.model'
import type { Order } from 'types/models/order'
import type { CartState } from '../stores/cart'
import { normalizePrice } from './price'
import { getItemsCountWithQuantity, getItemsPrice } from './cart'

export function convertToBrinkAddress(address: Contact.Address, information: Contact.Information): Brink.Address {
  if (!address.country || !isValidEnvironmentCountryCode(address.country))
    throw new Error('Missing country code')

  return {
    country: address.country,
    city: address.city,
    phone: information.telephone,
    streetAddress: address.street0,
    houseNumberOrName: address.street1 || '',
    familyName: address.lastName,
    postalCode: address.postCode,
    givenName: address.firstName,
    region: address.region,
  }
}

export function convertToAddress(address: Brink.Address, email = ''): { address: Contact.Address, information: Contact.Information } {
  return {
    address: {
      country: address.country,
      city: address.city,
      street0: address.streetAddress,
      lastName: address.familyName,
      firstName: address.givenName,
      postCode: address.postalCode,
      region: address.region,
    },
    information: {
      telephone: address.phone,
      email,
      agreements: true,
      newsletterSubscription: false,
    },
  }
}

export function normalizeOrder(order: Brink.Order, cart: CartState, shippingMethod: ShippingMethod | undefined): Order {
  const storefrontStore = useStorefrontStore()
  const shipmentLine = getShipmentOrderLine(order.orderLines)
  const { address, information } = convertToAddress(order.billingAddress, order.email)
  const orderStatus = order.status ?? order.statusLog[order.statusLog.length - 1]?.status

  if (!shipmentLine)
    throw new Error('No shipment line found in order')

  const orderItems = order.orderLines.filter(item => item.type === 'physical')

  return {
    id: order.id,
    status: orderStatus ?? 'unknown',
    items: normalizeOrderItems(orderItems, cart.items, order.currencyUnit),
    itemsCount: getItemsCountWithQuantity(orderItems),
    orderNumber: String(order.reference),
    priceTax: normalizeBrinkPrice(order.orderTaxAmount, order.currencyUnit),
    priceProducts: getBrinkOrderItemsPrice(orderItems, order.currencyUnit),
    priceShipping: normalizeBrinkPrice(shipmentLine.totalAmountWithDiscount, order.currencyUnit),
    priceDiscount: cart.priceDiscount,
    priceTotal: normalizeBrinkPrice(order.orderAmountWithDiscount, order.currencyUnit),
    storefront: storefrontStore.current,
    market: storefrontStore.currentMarket,
    currency: order.currencyUnit,
    payment: {
      status: orderStatus,
      method: order.paymentMethod ?? '',
    },
    address: {
      billing: address,
      shipping: convertToAddress(order.shippingAddress).address,
    },
    shippingMethod: {
      name: shipmentLine.name,
      estimatedDeliveryTime: shippingMethod?.deliveryDetails ?? '0', // Get from cart data
    },
    contact: {
      firstName: address.firstName,
      lastName: address.lastName,
      email: information.email,
      telephone: information.telephone,
    },
    createdAt: order.created ?? new Date().toISOString(),
    updatedAt: order.lastUpdated ?? new Date().toISOString(),
    externalTaxAmount:
      order.externalTaxRate && normalizeBrinkPrice(order.orderTaxAmount, order.currencyUnit),
  }
}

export function normalizeCartItems(products: Brink.CartItem[], currencyCode: Environment.Currency['code'], cart?: Brink.Cart): CartItem[] {
  // TODO: discounts, sections
  return products
    .filter(item => item.type === 'productVariant')
    .map((item) => {
      const price = normalizeBrinkPrice(item.price[currencyCode] || 0, currencyCode)
      const discount = normalizeBrinkPrice(item.discount[currencyCode] || 0, currencyCode)
      const productDiscounts = cart?.discounts?.productDiscounts?.find(discount => discount.id === item.id)

      let productDiscount = 0

      if (productDiscounts) {
        const amount = productDiscounts.discountAmount[currencyCode] || 0

        productDiscount = normalizeBrinkPrice(amount, currencyCode)
      }

      return {
        image: item.imageUrl,
        id: item.id, // barcode
        itemId: item.id, // !Deprecate!
        name: item.name,
        price: {
          original: price,
          final: price - discount,
        },
        sections: item.customerAttribute.itemSections || [],
        quantity: item.quantity,
        size: {
          label: 'size',
          value: item.customerAttribute.size,
        },
        sku: item.customerAttribute.skuNumber,
        parentSku: item.relatedProduct,
        stockStatus: 'in-stock',
        isGift: item.isGift,
        taxCode: item.customerAttribute['taxjar:product_tax_code'],
        productDiscount,
      }
    })
}

export function normalizeOrderItems(products: Brink.OrderLine[], cartItems: CartItem[], currencyCode: Environment.Currency['code']): CartItem[] {
  return products
    .filter(item => item.type === 'physical')
    .map((item) => {
      const cartItem = cartItems.find(cartItem => cartItem.id === item.productId)

      return {
        image: cartItem?.image ?? null,
        id: item.productId,
        itemId: cartItem?.itemId ?? '',
        name: item.name,
        price: {
          original: normalizeBrinkPrice(item.price, currencyCode),
          final: normalizeBrinkPrice(item.promotionPrice, currencyCode),
        },
        sections: [],
        quantity: item.quantity,
        size: {
          label: cartItem?.size.label ?? 'size',
          value: cartItem?.size.value ?? '0',
        },
        productDiscount: cartItem?.productDiscount ?? 0,
        sku: cartItem?.sku ?? '',
        parentSku: cartItem?.parentSku ?? '',
        variantId: 0,
        stockStatus: cartItem?.stockStatus ?? 'in-stock',
        externalTaxAmount:
          item.externalTaxRate && normalizeBrinkPrice(item.totalTaxAmount, currencyCode),
        isGift: cartItem?.isGift ?? false,
      }
    })
}

export function getShipmentOrderLine(orderLines: Brink.OrderLine[]) {
  return orderLines.find(item => item.type === 'shipment')
}

export function normalizeBrinkPrice(price: number, currency: Environment.Currency['code']) {
  switch (currency) {
    case 'JPY':
    case 'KRW':
      return price
    default:
      return normalizePrice(price)
  }
}

export function getDiscountCodeFromDiscounts(discounts: Brink.Discounts | object) {
  if (!isBrinkDiscount(discounts))
    return undefined
  return discounts.rules?.find(rule => rule.ruleType === 'DISCOUNTCODE')?.ruleData.discountCode
}

export function hasFreeShippingFromDiscount(discounts: Brink.Discounts | object) {
  if (!isBrinkDiscount(discounts))
    return false
  return discounts.orderRules.some(rule => rule.freeShipping)
}

// Brink totalDiscountAmount counts item discount so we cant count it here with price.final
export function getBrinkCartItemsPrice(items: any[]) {
  return getItemsPrice(items, 'price.original', 'quantity')
}

// Brink orderDiscountAmount does not count item discounts in contrast to Cart's totalDiscountAmount
// This returns the sum of all items with full price
export function getBrinkOrderItemsPrice(items: Brink.OrderLine[], currencyCode: Environment.Currency['code']) {
  return normalizeBrinkPrice(
    items.reduce((sum, item) => sum + item.totalAmount, 0),
    currencyCode,
  )
}

export function isBrinkError(error: any): error is Brink.Error {
  return error?.code !== undefined
}

export function isBrinkDiscount(discounts: any): discounts is Brink.Discounts {
  return ['rules', 'orderRules', 'productDiscounts', 'cartDiscount'].every(key =>
    Reflect.has(discounts, key),
  )
}

export function getBrinkErrorMessage(error: Brink.Error) {
  const { $t } = useNuxtApp()
  switch (error.code) {
    case BrinkCartErrorCodes.CART_CLOSED:
      return $t('noCartFound')
    case BrinkCartErrorCodes.CART_NOT_FOUND:
      return $t('noCartFound')
    case BrinkPaymentErrorCodes.TAX_JAR_ERROR:
      return error.message.replace('to_zip', 'zip').replace('to_state', 'state')

    default:
      return error.message
  }
}
